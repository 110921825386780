body {
  margin: 0;
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100dvw;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

#root {
  position: relative;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.modal-header .btn-close {
  color: #fff !important;
}

.add-button {
  position: absolute;
  top: 1px;
  left: 1px;
}

.myLoaderRounded {
  animation: loder 0.5s infinite ease-in-out;
}
@keyframes loder {
  0% {
    transform: rotate(0deg);
  }
  20% {
    transform: rotate(90deg);
  }
  30% {
    transform: rotate(100deg);
  }
  50% {
    transform: rotate(150deg);
  }
  60% {
    transform: rotate(180deg);
  }
  70% {
    transform: rotate(260deg);
  }
  80% {
    transform: rotate(300deg);
  }
  90% {
    transform: rotate(320deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
